<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col md="auto">
          <v-date-picker
              v-model="range"
              is-range
              :masks="masks"
              :columns="3"
              locale="ru"
              show-iso-weeknumbers
              title-position="center"
              mode="date"
              :timezone="'UTC'"
              is24hr
          >
          </v-date-picker>
        </b-col>
        <b-col>
          Период: {{dateFormat(range)}}<br><br>
          <a href="#" @click.prevent="filters.status='scanned'">Отсканированно:</a> <b>{{calcSumStats.scanned}} </b>({{calcSumStats.scannedErrors}} с ошибкой), wb: <b>{{calcSumStats.scannedWb}}</b>, ozon: <b>{{calcSumStats.scannedOzon}}</b><br>
          <a href="#" @click.prevent="filters.status='addedPoints'">Начислено баллов:</a> <b>{{ calcSumStats.addedPoints.allPoint || '?'}} </b>({{ calcSumStats.addedPoints.qty }}), ooo: {{calcSumStats.addedPoints.ooo}}, ip: {{calcSumStats.addedPoints.ip}}, vip: {{calcSumStats.addedPoints.viplinza}}, ozon: {{calcSumStats.addedPoints.ozon}}<br>
          <a href="#" @click.prevent="filters.status='errors'">Статистика ошибок:</a> <br>
          <div style="background-color: #e7e7e7;padding: 12px;width: 100%">
            <div v-for="(se, indx) of calcSumStats.errorStats" :key="`stats-${indx}`">{{se.code}} - {{se.qty}}
            </div>
          </div>

<!--          <pre>USED_STICKER_ERROR - 3</pre>-->
<!--          <pre>USER_NOT_FOUND_ERROR - 1</pre>-->

        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <b-form-input v-model="filters.key" placeholder="поиск по ключу"></b-form-input>
        </b-col>
        <b-col sm="3">
          <b-form-input v-model="filters.phone" placeholder="поиск по телефону"></b-form-input>
        </b-col>
        <b-col sm="3">
          <b-form-input v-model="filters.status" placeholder="поиск по статусу"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="filteredLogs"
              :fields="fields"
              stacked="md"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              :busy="isLoading"
              tbody-class="acuvue-logs"
              style="width: 99%"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>
            <template #cell(createdAt)="{item}">
              <a @click.prevent="filters.key=item.key">{{item.key}}</a>
<!--               <a :href="`https://oasys1.ru/${item.key}`" target="_blank">страница</a>-->
              <br>{{dateFormatBd(item.createdAt)}}
            </template>
            <template #cell(phone)="{item}">
              <template v-if="item.phone != 'no'"> <a @click.prevent="filters.phone=item.phone">{{item.phone}}</a>
                <span title="Кол-во раз воспользовался начислением баллов">({{reportLog.filter(e=>e.phone==item.phone).length}})</span>
              </template>
             </template>
            <template #cell(result)="{item}">
              <template v-if="item.result.code==0 && item.action=='request acuvue points'">
                <span class="text-success">Начислили <b>{{reportLog.find(el=>el.key==item.key && el.action=='open page' && el.result.code==0).result.extra.acuvuePoint}}</b> баллов. Всего {{item.result.extra.points}}</span>
              </template>
              <template v-else-if="item.result.code==0 && item.action=='request acuvue points with no-fitting'">
                <span class="text-success">Начислили <b>{{reportLog.find(el=>el.key==item.key && el.action=='open page' && el.result.code==0).result.extra.acuvuePoint}}</b> баллов. Всего {{item.result.extra.points}}</span> <span class="text-danger">без нужного подбора!</span>
              </template>
              <template v-else-if="item.result.code==10 || item.result.code==5">
                <span class="text-danger">{{item.result.code}} {{item.result.desc}}</span><br>
                Подборы у покупателя:
                <b-row>
                  <b-col sm="11" v-for="(podbor, indx) of item.result.extra.fittings" :key="`podbor-${indx}`">
                    {{podborFormat(podbor)}}
                  </b-col>
                </b-row>
              </template>
              <template v-else-if="item.result.code!=0 && (item.action=='request acuvue points with no-fitting' || item.action=='request acuvue points')">
                <span class="text-danger">{{item.result.code}} {{item.result.desc}}</span>
                {{item.result.extra}}
              </template>
              <template v-else-if="item.result.code==0 && item.action=='open page'">
                <span class="text-info">Отсканирован QR-CODE</span> {{item.result.extra.market}}<br>
                арт wb: {{item.result.extra.art_wb}}, заказ: {{item.result.extra.wb_order_id}}, {{item.result.extra.seller}}
               {{item.result.extra.name}}
              </template>
              <template v-else-if="item.result.code!=0 && item.action=='open page'">
                <span class="text-info">Отсканирован QR-CODE</span><br>
                <span class="text-danger">{{item.result.code}} {{item.result.desc}}</span>
                {{item.result.extra}}
              </template>
              <template v-else-if="item.action=='find vouchers'">
                <span class="text-info">Поиск купонов</span><br>
                <ul>
                  <li v-for="(cupon, indx) of item.result.extra" :key="cupon.name + '--' + indx">{{cupon.name}}, {{cupon.value}}, type: {{cupon.type}}</li>
                </ul>
              </template>

              <template v-else>
                {{item.action}}<br>
                <span class="text-danger">{{item.result.code}} {{item.result.desc}}</span><br>
                {{item.result.extra}}
              </template>
            </template>

          </b-table>
        </b-col>
      </b-row>
    </b-col>

  </b-row>

</template>

<style>
pre {
  font-size: 12px;
  margin: 0px
}
.acuvue-logs .table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
  font-size: 12px;
}
</style>

<script>
import _ from "lodash"
import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "acuvue-land-report-page",
  data() {
    return {
      range: {
        start: new Date(new Date().setDate(new Date().getDate())),
        end: new Date(new Date().setDate(new Date().getDate())),
      },
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      },
      reportLog: [],
      fields: [
        { key: 'createdAt', label: 'Время входа', sortable: true, sortDirection: 'desc' },
        { key: 'phone', label: 'Телефон', sortable: false, sortDirection: 'desc' },
        { key: 'result', label: 'Лог', sortable: false, sortDirection: 'desc' }
      ],
      isLoading: false,
      sumStats: {

      },
      filters: {
        key: '',
        status: '',
        phone: ''
      }
    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getReport()
  },
  methods: {
    getReport() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/acuvue-land/getReport`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            console.log(resp)
            if (resp.status) {
              this.reportLog = resp.items
              this.isLoading=false
            }
            // if (!resp.status) this.alertMsg('Ошибка загрузки выкупов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    podborFormat(e) {
      if (!e) throw 'err e'
      try {
        const curveL = e.fittedBrands[0].leftEye.find(el=>el.property=="BASE_CURVE").value
        const dioL = e.fittedBrands[0].leftEye.find(el=>el.property=="POWER").value
        const curveR = e.fittedBrands[0].rightEye.find(el=>el.property=="BASE_CURVE").value
        const dioR = e.fittedBrands[0].rightEye.find(el=>el.property=="POWER").value

        const params = (curveL==curveR && dioL==dioR)
            ? `${curveL};${dioL}`
            : `Л ${curveL};${dioL} П ${curveR};${dioR}`

        const createdDat = e.createdDateTime.split("T")[0]

        return `${createdDat} ${e.fittedBrands[0].brandId} ${params}`
      } catch (err) {
        return []
      }

    },
    dateFormat(period) {
      return `с ${dayjs(period.start).format("DD.MM.YYYY")} по ${dayjs(period.end).format("DD.MM.YYYY")}`
    },
    dateFormatBd(date) {
      return dayjs(date).format("DD.MM.YYYY hh:mm:ss")
    }
  },
  computed: {
    filteredLogs: function () {
      let result = this.reportLog //.slice(0,5)

       // result = result.filter(e=>{
       //   console.log({
       //     createdAt: e.createdAt,
       //     range: dayjs(this.range.start).format('YYYY-MM-DD')
       //   })
       //   console.log(dayjs(e.createdAt).format('YYYY-MM-DD')==dayjs(this.range.start).format('YYYY-MM-DD'))
       //   return true
       // })
      if (dayjs(this.range.start).format('YYYY-MM-DD') == dayjs(this.range.end).format('YYYY-MM-DD')) {
        result = result.filter(e=>dayjs(e.createdAt).format('YYYY-MM-DD') == dayjs(this.range.start).format('YYYY-MM-DD'))
      } else {
        result = result.filter(e=>dayjs(e.createdAt).format('YYYY-MM-DD') >= dayjs(this.range.start).format('YYYY-MM-DD') && dayjs(e.createdAt).format('YYYY-MM-DD') <= dayjs(this.range.end).format('YYYY-MM-DD'))
      }
      if (this.filters.key) result = result.filter(e=>e.key==this.filters.key)
      if (this.filters.status=='addedPoints') result = result.filter(e=>(e.action=='request acuvue points with no-fitting' || e.action=='request acuvue points') && e.result.code==0)
      if (this.filters.status=='scanned') result = result.filter(e=>e.action=='open page')
      if (this.filters.status=='errors') result = result.filter(e=>e.result.code!=0)
      if (this.filters.phone) result = result.filter(e=>e.phone==this.filters.phone)

      return result
    },
    calcSumStats: function() {
      const findCurPoints = this.filteredLogs
          .filter(e=>(e.action=='request acuvue points with no-fitting' || e.action=='request acuvue points') && e.result.code==0)
          .map(e=>({
            key: e.key,
            cur_point: this.reportLog.find(el=>el.key==e.key && el.action=='open page' && el.result.code==0).result.extra.acuvuePoint,
            seller: this.reportLog.find(el=>el.key==e.key && el.action=='open page' && el.result.code==0).result.extra.seller,
            market: this.reportLog.find(el=>el.key==e.key && el.action=='open page' && el.result.code==0).result.extra.market
          }))

      return {
        scanned: this.filteredLogs.filter(e=>e.action=='open page' && e.result.code==0).length,
        scannedErrors: this.filteredLogs.filter(e=>e.action=='open page' && e.result.code!=0).length,
        scannedWb: this.filteredLogs.filter(e=>e.action=='open page' && e.result.code==0 && e.result.extra.market=='wb').length,
        scannedOzon: this.filteredLogs.filter(e=>e.action=='open page' && e.result.code==0 && e.result.extra.market=='ozon').length,
        addedPoints: {
          allPoint: findCurPoints.reduce((t, {cur_point})=>t+parseInt(cur_point),0),
          qty: findCurPoints.length,
          ooo: findCurPoints.filter(e=>e.seller=='ooo').reduce((t, {cur_point})=>t+parseInt(cur_point),0),
          ip: findCurPoints.filter(e=>e.seller=='ip').reduce((t, {cur_point})=>t+parseInt(cur_point),0),
          viplinza: findCurPoints.filter(e=>e.seller=='vipl').reduce((t, {cur_point})=>t+parseInt(cur_point),0),
          wb: findCurPoints.filter(e=>e.market=='wb').reduce((t, {cur_point})=>t+parseInt(cur_point),0),
          ozon: findCurPoints.filter(e=>e.market=='ozon').reduce((t, {cur_point})=>t+parseInt(cur_point),0),
        },
        errorStats:_.orderBy(_.chain(this.filteredLogs.map(el=>el.result))
            .groupBy("code")
            .map((valu, ke)=>({
              code: (ke==0) ? null : `${ke}_${valu[0].desc}`,
              qty: valu.length
            }))
            .filter(e=>e.code)
            .value(), 'qty', 'desc')
      }
    }
  },
};
</script>